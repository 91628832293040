/* eslint-disable no-unused-vars */
import { Stack } from "@fluentui/react";
import React, { useState } from "react";
import { Form, FormButton, Breadcrumb } from "../../../components";
import { useForm } from "react-hook-form";
import Layout from "../../../styles/Layout.styles";
import {
  Toast,
  ToastTitle,
  Toaster,
  mergeClasses,
  useToastController,
} from "@fluentui/react-components";
import Style from "../../Client/ClientManagement/AddClient.style";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useEditUsersMutation,
  useGetUsersMutation,
  useGetUsersPhotoMutation,
  useUploadAddUsersPhotoMutation,
} from "../../../store/action/users";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trailWhiteSpace } from "../../../utils/trailWhiteSpace";
import { formErrorHandler } from "../../../utils/errorHandler";
import accessRole from "../../../components/Common/accessRole";
import { useId } from "react";
import UserFirstName from "./Form/UserFirstName";
import UserLastName from "./Form/UserLastName";
import UserPhoneNumber from "./Form/UserPhoneNumber";
import UserEmailAddress from "./Form/UserEmailAddress";
import UserAddress from "./Form/UserAddress";
import UserCountry from "./Form/UserCountry";
import UserState from "./Form/UserState";
import UserTimeZone from "./Form/UserTimeZone";
import UserCity from "./Form/UserCity";
import UserRole from "./Form/UserRole";
import UserPhotoUpload from "./Form/UserPhotoUpload";
import StaticStyle from "../../Client/ClientManagement/Style";
import UserClient from "./Form/UserClient";
import UserProfilePasscodeGeneration from "./Form/UserProfilePasscodeGeneration";
import { setUser } from "../../../store/reducer/user";

const UsersForm = (props) => {
  const { id } = props;
  const layout = Layout();
  const style = Style();
  const [userEditData, setUserEditData] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);
  const [timeZoneSelectListData, setTimeZoneSelectListData] = useState();
  const [getUserstApi, { isLoading: isGetLoading }] = useGetUsersMutation();
  const [editUsersApi, { isLoading: isEditLoading }] = useEditUsersMutation();
  const [addUploadUsersPhoto, { isLoading: isPhotoEditLoading }] =
    useUploadAddUsersPhotoMutation();
  const [getUsersPhotoApi] = useGetUsersPhotoMutation();
  //eslint-disable-next-line no-unused-vars
  const [isDeletePhoto, setIsDeletePhoto] = useState(false);
  const [getUsersPhoto, setGetUsersPhoto] = useState();
  const [isUsersPhoto, setIsUsersPhoto] = useState();
  const [isEditDisable, setIsEditDisable] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [userClient, setUserClient] = useState();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const userProfileForm = useSelector((state) => state.users);

  const { t } = useTranslation();
  StaticStyle();

  const breadcrumbData = [
    {
      title: `${t("edit_profile")}`,
    },
  ];

  const dispatch = useDispatch();
  const refreshPermission = () => {
    dispatch(
      setUser({
        reloadPage: true,
      })
    );
  };

  useEffect(() => {
    if (initialLoad) {
      if (!isGetLoading) {
        if (id) {
          getUserstApi(id).then((res) => {
            setUserEditData(res);
            setIsEditDisable(true);
          });
          getUsersPhotoApi(id).then((res) => {
            setGetUsersPhoto(res?.data?.imageUrl);
          });
        }
      }
      setInitialLoad(false);
    }
  }, [initialLoad, isGetLoading, getUserstApi, id, getUsersPhotoApi]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    resetField,
  } = useForm();

  const notify = (message, valid) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{`${message}`}</ToastTitle>
      </Toast>,
      { intent: valid ? "success" : "error" }
    );
  };

  const onSubmit = (value) => {
    let formData = trailWhiteSpace(value);
    const newUserData = {
      email: formData?.userEmailAddress,
      firstName: formData?.userFirstName,
      lastName: formData?.userLastName,
      phonePrefix: formData?.userPhonePrefix,
      phoneNumber: formData?.userPhoneNumber,
      roles:
        localStorage.edit_profile === "true" &&
        !userEditData?.data?.roles.includes("ROLE_CLIENT_ADMIN")
          ? userEditData?.data?.roles
          : userEditData?.data?.roles.includes("ROLE_TNA_LICENSED_USER")
          ? [...selectedOptions, "ROLE_TNA_LICENSED_USER"]
          : selectedOptions,

      password: userEditData?.data?.userSetPassWord,
      address: formData?.userAddress,
      city: formData?.userCity,
      state: formData?.userState,
      country: formData?.userCountry,
      timezone: formData?.userTimeZone,
      client: accessRole()?.has("assign_organization")
        ? `/client/${userClient ? userClient : userEditData?.data?.client?.id}`
        : undefined,
      profileImageDeleted: isDeletePhoto,
    };

    if (id) {
      if (!isEditLoading) {
        editUsersApi({ usersData: newUserData, id: id }).then((res) => {
          let errorLists = res?.error?.data?.violations;
          let error = res?.error;

          if (errorLists || error) {
            formErrorHandler("user", errorLists, setError, true);
            notify(t("profile_not_updated"), false);
          } else {
            localStorage.setItem("activeUser", res?.data?.fullName);
            refreshPermission();
            notify(t("profile_updated"), true);
          }

          let formData = new FormData();
          if (isUsersPhoto && !isPhotoEditLoading) {
            formData.append("fileUpload", isUsersPhoto);
            addUploadUsersPhoto({ formData, id }).then((resLogo) => {
              localStorage.setItem(
                "activeUserImage",
                resLogo?.data?.profileImageUrl
              );
              refreshPermission();
            });

            if (errorLists || error) {
              formErrorHandler("user", errorLists, setError, true);
              notify(t("profile_not_updated"), false);
            } else {
              refreshPermission();
            }
          }
        });
      }
    }
  };

  if (id && !userEditData?.data) {
    return null;
  }

  const commonProps = { register, errors, setValue, resetField };

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <Stack>
        <Toaster position="top" timeout={3000} toasterId={toasterId} />
        <Form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <Stack
            className={mergeClasses(
              style.clientContent,
              "clientContent",
              layout.borderRadiusMedium
            )}
          >
            <UserFirstName
              {...commonProps}
              validationMessage={
                userProfileForm?.errorMessage?.firstName &&
                userProfileForm?.errorMessage?.firstName?.validationMessage
              }
              userFirstName={userEditData?.data?.firstName}
            />
            <UserLastName
              {...commonProps}
              userLastName={userEditData?.data?.lastName}
            />
            <UserPhoneNumber
              {...commonProps}
              userPhoneNumber={userEditData?.data?.phoneNumber}
              userPhonePrefix={userEditData?.data?.phonePrefix}
            />

            <UserEmailAddress
              {...commonProps}
              isEditDisable={isEditDisable}
              userEmailAddress={userEditData?.data?.email}
            />
            {localStorage.edit_profile === "true" &&
            userEditData?.data?.roles.includes("ROLE_CLIENT_ADMIN") ? (
              <UserRole
                {...commonProps}
                userRoles={userEditData?.data?.roles}
                setSelectedOptions={setSelectedOptions}
                selectedOptions={selectedOptions}
                isEditProfile={true}
              />
            ) : (
              ""
            )}

            <UserProfilePasscodeGeneration
              {...commonProps}
              userClientName={userEditData?.data?.client}
              setUserClient={setUserClient}
              isEditDisable={isEditDisable}
              id={id}
            />

            {accessRole()?.has("assign_organization") && (
              <UserClient
                {...commonProps}
                userClientName={userEditData?.data?.client}
                setUserClient={setUserClient}
              />
            )}

            <UserAddress
              {...commonProps}
              userAddress={userEditData?.data?.address}
            />
            <UserCountry
              {...commonProps}
              userCountry={userEditData?.data?.country}
              setTimeZoneSelectListData={setTimeZoneSelectListData}
            />
            <UserState {...commonProps} userState={userEditData?.data?.state} />
            <UserCity {...commonProps} userCity={userEditData?.data?.city} />

            <UserTimeZone
              {...commonProps}
              userTimeZone={userEditData?.data?.timezone}
              timeZoneSelectListData={timeZoneSelectListData}
            />

            <UserPhotoUpload
              {...commonProps}
              validation={register("fileUpload")}
              setFileCallback={setIsUsersPhoto}
              userPhoto={getUsersPhoto}
              setIsDeletePhoto={setIsDeletePhoto}
            />

            <Stack className={style.formfooter}>
              <FormButton
                type="submit"
                appearance="primary"
                loading={isEditLoading || isPhotoEditLoading}
                className={mergeClasses(style.customBtn, "custom-primary")}
              >
                {t("save")}
              </FormButton>

              <Link
                to="/project-list"
                appearance="primary"
                className={mergeClasses(style.customBtn)}
              >
                <FormButton className={layout.min150}>{t("cancel")}</FormButton>
              </Link>
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </>
  );
};

export default UsersForm;
